html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #f3f3f3;
}

.menu-item {
  color: black;
  font-size: large;
  font-weight: 500;
}

.menu-item:hover {
  color: #009933;
  cursor: pointer;
}

.menu-active-item {
  color: #009933;
}

.bottom-menu-item {
  color: #f0f0f0;
  font-size: medium;
  font-weight: 500;
}

.bottom-menu-item:hover {
  color: #f0f0f0;
  opacity: 0.9;
}

.page-name:after {
  content: "";
  width: 100px;
  height: 5px;
  display: block;
  background: #009933;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.category {
  overflow: hidden;
}

.category img {
  transition: transform 1s ease;
}

.category img:hover {
  opacity: 0.9;
  transform: scale(1.2);
}

@keyframes visibilityAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes upscaleAnimation {
  from {
    transform: scale(0.5) rotate(-10deg);
    -webkit-transform: scale(0.5) rotate(-10deg);
  }
  to {
    transform: scale(1) rotate(0deg);
    -webkit-transform: scale(1) rotate(0deg);
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit;
  color: red;
}
